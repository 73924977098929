<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import WebsiteLayout from '@/Layouts/WebsiteLayout.vue';
import RestaurantHead from '@/Components/Shared/Restaurant/RestaurantHead.vue';
import route from '@/Helpers/route.js';
import Container from '@/Components/Shared/Container.vue';
import Card from '@/Components/Shared/Cards/Card.vue';

const props = defineProps({
    restaurant: Object,
    logo: String,
    responsiveLogo: String,
    status: Number,
});

const title = computed(() => {
    return {
        503: 'Wij zijn momenteel bezig met een update',
        500: 'Interne serverfout',
        404: '404: Niet gevonden',
        403: '403: Verboden toegang',
    }[props.status];
});

const description = computed(() => {
    return {
        503: 'Beste klant, wij zijn momenteel bezig met het updaten van onze systeem. Kom over enkele minuten terug en probeer het nogmaals.',
        500: 'Whoops, er is iets mis gegaan op onze servers. Wij krijgen hier een automatische bericht van en gaan het probleem zo snel mogelijk oplossen.',
        404: 'Sorry, de pagina die je probeert te bezoeken bestaat niet (meer).',
        403: 'Sorry, je hebt geen toegang tot deze pagina.',
    }[props.status];
});
</script>

<template>
    <RestaurantHead :restaurant="restaurant" />

    <WebsiteLayout :restaurant="restaurant">
        <Container class="py-12">
<!--            <Link href="/" class="block mb-12">-->
<!--                <img :src="restaurant.links.logo" :srcset="restaurant.links.responsive_logo" :alt="`Logo ${restaurant.name}`" class="block w-48 mx-auto">-->
<!--            </Link>-->

            <Card class="w-full md:w-1/2 mb-12 p-4 mx-auto">
                <h2 class="font-semibold text-2xl leading-tight mb-4">{{ title }}</h2>
                <h3 class="text-lg leading-tight mb-4">{{ description }}</h3>
            </Card>

            <div class="w-full md:w-1/2 flex flex-col md:flex-row gap-4 mx-auto">
                <Link :href="route('website.menu', { restaurant })" class="
                    flex w-full items-center justify-center
                    cursor-pointer font-medium
                    rounded-xl transition ease-in-out duration-150
                    bg-[var(--primary)] text-white p-4
                " v-if="restaurant.can_deliver || restaurant.can_pickup">
                    Direct bestellen
                </Link>

                <Link :href="route('website.reservations.create', { restaurant })" class="
                    flex w-full items-center justify-center
                    cursor-pointer font-medium
                    rounded-xl transition ease-in-out duration-150
                    bg-[var(--accent)] text-white p-4
                " v-if="restaurant.accepts_reservations">
                    Direct reserveren
                </Link>
            </div>
        </Container>
    </WebsiteLayout>
</template>
